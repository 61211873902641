import '../../App.css'
import React from 'react';
import DemosSection from '../DemosSection';

function Demos() {
    return (
        <>
            <DemosSection />
        </>
    );
}

export default Demos;